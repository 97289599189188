import React, { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
} from "@mui/material";

// project import
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"; // assets
import { useNavigate } from "react-router";

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Calender = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const anchorRef = useRef(null);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Calender">
        <IconButton
          disableRipple
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: "grey.100",
          }}
          aria-label="open calender"
          ref={anchorRef}
          aria-haspopup="true"
          onClick={() => {
            navigate("/calender");
          }}
        >
          <Badge color="primary">
            <CalendarMonthOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Calender;
