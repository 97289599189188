import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import BreakdownData from "pages/Preventive-maintenance/machine-details/BreakdownData";
import TicketWorkFlow2 from "pages/Preventive-maintenance/configuration/TicketWorkFlow2";
import MainDashboard from "pages/dashboard/MainDashboard";
import CompanyProfileForm from "pages/company-details/CompanyProfileForm";
import { useLocation } from "react-router";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const Tickets = Loadable(
  lazy(() => import("pages/Preventive-maintenance/ticket-details/Tickets"))
);
// const MachineDetails = Loadable(lazy(() => import('pages/Preventive-maintenance/machine-details/MachineDetails')));
const AddMachine = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/MachineConfiguration")
  )
);
const TicketDetails = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/ticket-details/TicketDetails")
  )
);
const Machine = Loadable(
  lazy(() => import("pages/Preventive-maintenance/machine-details/Machine"))
);

const VendorRegistration = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/VendorRegistration/VendorRegistraion")
  )
);

const TicketWorkFlow = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/TicketWorkFlow")
  )
);

const UserConfig = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/UserConfig"))
);
const TimeLineComponent = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/ticket-details/TimeLineComponent")
  )
);
const AccessControl = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AccessControl"))
);

const AddLocation = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AddLocation"))
);

const UserProfile = Loadable(lazy(() => import("../components/UserProfile")));

const VendorReg = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/VendorSecurity/RegisterVendor")
  )
);
const Vendordetails = Loadable(
  lazy(() => import("pages/Preventive-maintenance/VendorSecurity/ViewVisitor"))
);
const VendorApproval = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/VendorSecurity/ApproveVendor")
  )
);

const ViewCheckList = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/VendorSecurity/ViewChecklist")
  )
);

const Report = Loadable(
  lazy(() => import("pages/Preventive-maintenance/machine-details/Report"))
);

const DemoFlow = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/DemoFlow"))
);

const NotificationPage = Loadable(
  lazy(() => import("../components/NotificationPage"))
);

const Calender = Loadable(
  lazy(() => import("pages/Preventive-maintenance/machine-details/Calender"))
);

const MaintenanceData = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/MaintenanceData")
  )
);
const SpareParts = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/SpareManagement")
  )
);

const SpareInward = Loadable(
  lazy(() => import("pages/Preventive-maintenance/machine-details/SpareInward"))
);

const SpareConfiguration = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/SpareConfigurations")
  )
);

const AssetConfiguration = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/AssetConfiguration")
  )
);

const SpareAnalytics = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/SpareAnalytics")
  )
);

const AssetAnalytics2 = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/AssetAnalytics2")
  )
);

const AssetAnalytics = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/AssetAnalytics")
  )
);

const AssetDetails = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/machine-details/AssetDetails")
  )
);

// render - sample page

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const use_location = useLocation();
  const machine_id =
    use_location.pathname.match(/\d+/) &&
    parseInt(use_location.pathname.match(/\d+/)[0], 10);
  return {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <DashboardDefault />,
      },
      {
        path: "/dashboard",
        element: <DashboardDefault />,
      },
      {
        path: "/main-dashboard",
        element: <MainDashboard />,
      },
      {
        path: "/tickets",
        element: <Tickets />,
      },
      {
        path: "/add-asset",
        element: <AddMachine />,
      },
      {
        path: "/asset",
        element: <Machine />,
      },
      {
        path: "/report",
        element: <Report />,
      },

      {
        path: "/create-ticket",
        element: <TicketDetails />,
      },
      {
        path: "/ticket-work-flow",
        element: <TicketWorkFlow />,
      },
      {
        path: "/ticket-work-flow2",
        element: <TicketWorkFlow2 />,
      },
      {
        path: "/ticket-details",
        element: <TimeLineComponent />,
      },
      {
        path: "/user-configuration",
        element: <UserConfig />,
      },
      {
        path: "/notifications",
        element: <NotificationPage />,
      },
      {
        path: "/access-control",
        element: <AccessControl />,
      },

      {
        path: "/location-configuration",
        element: <AddLocation />,
      },

      {
        path: "/user-profile",
        element: <UserProfile />,
      },

      {
        path: "/registerVendor",
        element: <VendorReg />,
      },
      {
        path: "/visitor-details",
        element: <Vendordetails />,
      },
      {
        path: "/approveVendor",
        element: <VendorApproval />,
      },
      {
        path: "/checklist",
        element: <ViewCheckList />,
      },
      {
        path: "/designation-hierarchy",
        element: <DemoFlow />,
      },
      {
        path: "/calender",
        element: <Calender />,
      },
      {
        path: "/maintenance-data",
        element: <MaintenanceData />,
      },
      {
        path: "/break-down",
        element: <BreakdownData />,
      },
      {
        path: "/register-vendor",
        element: <VendorRegistration />,
      },
      {
        path: "/company-profile",
        element: <CompanyProfileForm />,
      },
      {
        path: "/spare-parts",
        element: <SpareParts />,
      },
      {
        path: "/spare-inward",
        element: <SpareInward />,
      },
      {
        path: "/spare-configuration",
        element: <SpareConfiguration />,
      },
      {
        path: "/asset-configuration",
        element: <AssetConfiguration />,
      },
      {
        path: "/spare-analytics",
        element: <SpareAnalytics />,
      },
      {
        path: `/asset/${machine_id}`,
        element: <AssetDetails />,
      },
      {
        path: machine_id
          ? `/analytics/${machine_id}`
          : "/analytics",
        element: <AssetAnalytics2 />,
      },
      {
        path: machine_id ? `/asset-analytic/${machine_id}` : `/asset-analytic`,
        element: <AssetAnalytics />,
      },
    ],
  };
};

export default MainRoutes;
