import React, { useState, useEffect } from "react";

// import { DashboardOutlined } from '@ant-design/icons';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
// assets
import { ControlOutlined } from "@ant-design/icons";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsInputSvideoOutlinedIcon from "@mui/icons-material/SettingsInputSvideoOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ChromeOutlined, QuestionOutlined } from "@ant-design/icons";
import FmdGoodIcon from "@mui/icons-material/FmdGoodOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import TuneIcon from "@mui/icons-material/Tune";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import { useTranslation } from "react-i18next";

const icons = {
  DashboardOutlinedIcon,
  CasinoOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  CategoryOutlinedIcon,
  PersonOutlineIcon,
  AssignmentTurnedInOutlinedIcon,
  AssignmentIcon,
  SchemaOutlinedIcon,
  SettingsInputSvideoOutlinedIcon,
  PersonOutlineOutlinedIcon,
  ControlOutlined,
  PermDataSettingIcon,
  SettingsOutlinedIcon,
  ChromeOutlined,
  FmdGoodIcon,
  QuestionOutlined,
  GroupsIcon,
  TuneIcon,
  SupervisorAccountIcon,
  HowToRegIcon,
  ManageAccountsIcon,
  KeyboardDoubleArrowRightIcon,
  ViewQuiltOutlinedIcon,
  TimelineOutlinedIcon,
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  const [access_list_data, setaccess_list_data] = useState([]);
  const [refresh, set_refresh] = useState([]);

  const { t } = useTranslation();

  // useEffect(() => {
  //   i18n.changeLanguage("fr");
  // }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("access_list_data");
    if (storedData) {
      setaccess_list_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  return [
    access_list_data?.some(
      (item) =>
        (item.module_name === "Dashboard" ||
          item.module_name === "Asset" ||
          item.module_name.toLowerCase()?.includes("tickets") ||
          item.module_name.toLowerCase()?.includes("created")) &&
        item.read_access
    ) && {
      id: "group-dashboard",
      title: "Menu",
      type: "group",
      children: [
        // {
        //   id: "main-dashboard",
        //   title: "Dashboard",
        //   type: "item",
        //   icon: icons.SchemaOutlinedIcon,
        //   url: "/main-dashboard",
        //   breadcrumbs: true,
        //   showMenu: access_list_data?.find(
        //     (item) => item.module_name === "Dashboard"
        //   )?.read_access,
        // },
        {
          id: "dashboard",
          title: t("Dashboard"),
          type: "item",
          url: "/dashboard",
          icon: icons.DashboardOutlinedIcon,
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Dashboard"
          )?.read_access,
        },

        {
          id: "",
          title: "Notifications",
          type: "item",
          url: "/notifications",
          icon: icons.DashboardOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "",
          title: "Calender",
          type: "item",
          url: "/calender",
          icon: icons.DashboardOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: t("Tickets"),
          type: "item",
          url: "/tickets",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: access_list_data
            ?.filter(
              (module) =>
                module.module_name.toLowerCase()?.includes("tickets") ||
                module.module_name.toLowerCase()?.includes("created")
            )
            ?.some((module) => module.read_access),
        },

        {
          id: "Asset Management",
          title: t("Asset Management"),
          type: "collapse",
          icon: icons.CategoryOutlinedIcon,
          breadcrumbs: true,
          showMenu: true,
          children: [
            {
              id: "machine",
              title: t("Asset"),
              type: "item",
              url: "/asset",
              icon: icons.CategoryOutlinedIcon,
              breadcrumbs: true,
              showMenu: true,
            },
            {
              id: "analytics",
              title: t("Analytics"),
              type: "item",
              icon: icons.TimelineOutlinedIcon,
              url: "/analytics",
              breadcrumbs: true,
              showMenu: true,
            },
            {
              id: "asset-analytics2",
              title: t("Asset Analytics"),
              type: "item",
              icon: icons.TimelineOutlinedIcon,
              url: "/asset-analytic",
              breadcrumbs: true,
              showMenu: true,
            },
          ],
        },

        {
          id: "spare_management",
          title: t("Spare Management"),
          type: "collapse",
          icon: icons.ViewQuiltOutlinedIcon,
          breadcrumbs: true,
          showMenu: true,
          children: [
            {
              id: "spare",
              title: t("Spare"),
              type: "item",
              url: "/spare-parts",
              icon: icons.ViewQuiltOutlinedIcon,
              breadcrumbs: true,
              showMenu: true,
            },
            {
              id: "inward",
              title: t("Inward"),
              type: "item",
              icon: icons.KeyboardDoubleArrowRightIcon,
              url: "/spare-inward",
              breadcrumbs: true,
              showMenu: true,
            },
            {
              id: "spare-analytics",
              title: t("Analytics"),
              type: "item",
              icon: icons.TimelineOutlinedIcon,
              url: "/spare-analytics",
              breadcrumbs: true,
              showMenu: true,
            },
          ],
        },
        {
          id: "machine",
          title: "Add Machine",
          type: "item",
          url: "/add-asset",
          icon: icons.DashboardOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "report",
          title: "Report",
          type: "item",
          url: "/report",
          icon: icons.AssignmentIcon,
          breadcrumbs: true,
          showMenu: false,
        },

        {
          id: "tickets",
          title: "Ticket Details",
          type: "item",
          url: "/ticket-details",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/create-ticket",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/maintenance-data",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "tickets",
          title: "Create Ticket",
          type: "item",
          url: "/break-down",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "",
          title: "User Profile",
          type: "item",
          url: "/user-profile",
          icon: icons.ConfirmationNumberOutlinedIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "Visitor_Management",
          title: t("Visitor Management"),
          type: "collapse",
          icon: icons.ManageAccountsIcon,
          breadcrumbs: true,
          showMenu: access_list_data?.some(
            (item) =>
              (item.module_name === "Visitor Details" ||
                item.module_name === "Visitor Approval") &&
              item.read_access
          ),
          children: [
            {
              id: "vendorConfig",
              title: t("Visitor Details"),
              type: "item",
              icon: icons.SupervisorAccountIcon,
              url: "/visitor-details",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Visitor Details"
              )?.read_access,
            },
            {
              id: "vendorApproval",
              title: t("Visitor Approval"),
              type: "item",
              icon: icons.HowToRegIcon,
              url: "/approveVendor",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Visitor Approval"
              )?.read_access,
            },
          ],
        },
        {
          id: "settings",
          title: t("Settings"),
          type: "collapse",
          icon: icons.TuneIcon,
          breadcrumbs: true,
          showMenu: access_list_data?.some(
            (item) =>
              (item.module_name === "Ticket_work_flow" ||
                item.module_name === "User_Configuration" ||
                item.module_name === "Location_Configuration" ||
                item.module_name === "Designation_Hierarchy" ||
                item.module_name === "Access_Control" ||
                item.module_name === "Company Profile") &&
              item.read_access
          ),
          children: [
            {
              id: "ticketFlow",
              title: t("Ticket Work Flow"),
              type: "item",
              icon: icons.SchemaOutlinedIcon,
              url: "/ticket-work-flow",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Ticket_work_flow"
              )?.read_access,
            },
            {
              id: "userConfig",
              title: t("User Configuration"),
              type: "item",
              icon: icons.PersonOutlineOutlinedIcon,
              url: "/user-configuration",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "User_Configuration"
              )?.read_access,
            },
            {
              id: "AddLocation",
              title: t("Location Configuration"),
              type: "item",
              icon: icons.FmdGoodIcon,
              url: "/location-configuration",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Location_Configuration"
              )?.read_access,
            },
            // {
            //   id: "spare_config",
            //   title: "Spare Configuration",
            //   type: "item",
            //   icon: icons.FmdGoodIcon,
            //   url: "/spare-configuration",
            //   breadcrumbs: true,
            //   showMenu: true,
            // },
            // {
            //   id: "asset_config",
            //   title: "Asset Configuration",
            //   type: "item",
            //   icon: icons.FmdGoodIcon,
            //   url: "/asset-configuration",
            //   breadcrumbs: true,
            //   showMenu: true,
            // },
            {
              id: "designation-hierarchy",
              title: t("Designation Hierarchy"),
              type: "item",
              url: "/designation-hierarchy",
              icon: icons.ChromeOutlined,
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Designation_Hierarchy"
              )?.read_access,
            },
            {
              id: "accessControl",
              title: t("Access Control"),
              type: "item",
              icon: icons.ControlOutlined,
              url: "/access-control",
              breadcrumbs: true,
              showMenu: access_list_data?.find(
                (item) => item.module_name === "Access_Control"
              )?.read_access,
            },
            {
              id: "Comapny Details",
              title: t("Company Details"),
              type: "item",
              icon: icons.GroupsIcon,
              url: "/company-profile",
              breadcrumbs: true,
              showMenu: access_list_data?.some(
                (item) =>
                  item.module_name === "Company Profile" &&
                  item.read_access &&
                  item.create_access
              ),
            },
          ],
        },
      ],
    },

    {
      id: "support",
      title: t("Support"),
      type: "group",
      children: [
        {
          id: "documentation",
          title: t("Documentation"),
          type: "item",
          url: "https://nexgensis-1.gitbook.io/maintenance-web-application/",
          icon: icons.QuestionOutlined,
          external: true,
          target: true,
          showMenu: true,
        },
        // {
        //   id: "demo_flow",
        //   title: "Demo Ticket Work Flow",
        //   type: "item",
        //   icon: icons.SchemaOutlinedIcon,
        //   url: "/ticket-work-flow2",
        //   breadcrumbs: true,
        //   showMenu: true,
        // },
      ],
    },
  ].filter(Boolean);
}

export default Dashboard;
